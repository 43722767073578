// import { Tabs } from 'antd';
import React from 'react';
import AccountsTable from './AccountsTable';
// const { TabPane } = Tabs;

const Accounts = () => {
  return (
    <div className="request-page-list">
      <div className="accounts-page boxes">
        <div className="box account">
          <div className="box__title-inner">
            <div className="text-black-bold-f19-l33">Рахунки</div>
            <div className="text-grey-dark-normal-f16-l28">
              Тут зберігаються усі рахунки компанії та їх статус виконання.
            </div>
          </div>
          <AccountsTable />

          {/* <Tabs className="tabs" defaultActiveKey="1">
            <AccountsTable />

            <TabPane tab="Рахунки" key="1">
            </TabPane>
            <TabPane tab="Платежі" key="2">
              <AccrualTable />
            </TabPane>
          </Tabs> */}
        </div>
      </div>
    </div>
  );
};

export default Accounts;
