import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { session } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';

// Выносим константы
const MINIMUM_PASSWORD_LENGTH = 8;
const REDIRECT_DELAY = 3000;

// Выносим URL в отдельный объект
const API_ROUTES = {
  PASSWORD: new URL(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`),
  CHECK_TOKEN: (code) =>
    new URL(`${process.env.REACT_APP_API_URL}/api/check/reset-password/${code}`),
};

// Выносим валидацию в отдельные функции
const validatePassword = (password) => password.length >= MINIMUM_PASSWORD_LENGTH;
const validatePasswordMatch = (password, confirmation) => password === confirmation;

const ChangePassword = () => {
  const token = session.get('token');
  const { push } = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  const [formState, setFormState] = useState({
    password: '',
    passwordConfirmation: '',
    errorsInPost: '',
    isSuccess: '',
    showMismatchError: false,
    showPasswordHint: false,
    isTokenValid: null,
  });

  useEffect(() => {
    if (token) {
      push('/signin');
      return;
    }

    const validateToken = async () => {
      try {
        const response = await apiInstance(API_ROUTES.CHECK_TOKEN(code), 'GET');
        setFormState((prev) => ({ ...prev, isTokenValid: response?.link_valid }));
      } catch {
        setFormState((prev) => ({ ...prev, isTokenValid: false }));
      }
    };

    validateToken();
  }, [token, code, push]);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setFormState((prev) => ({
      ...prev,
      password: value,
      showPasswordHint: value.length > 0 && !validatePassword(value),
    }));
  };

  const handlePasswordConfirmationChange = (e) => {
    const { value } = e.target;
    setFormState((prev) => ({
      ...prev,
      passwordConfirmation: value,
      showMismatchError: value && !validatePasswordMatch(prev.password, value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      password: formState.password,
      passwordConfirmation: formState.passwordConfirmation,
      code,
    };

    try {
      await apiInstance(API_ROUTES.PASSWORD, 'POST', payload);
      setFormState((prev) => ({
        ...prev,
        errorsInPost: '',
        isSuccess: 'Пароль успішно змінено! Перенаправляємо на сторінку входу...',
      }));
      setTimeout(() => push('/signin'), REDIRECT_DELAY);
    } catch {
      setFormState((prev) => ({
        ...prev,
        errorsInPost: 'Помилка при зміні пароля.',
      }));
    }
  };

  if (formState.isTokenValid === null) {
    return <div>Завантаження...</div>;
  }

  if (formState.isTokenValid === false) {
    return (
      <InvalidTokenView
        onRecoveryClick={() => push('/recovery-password')}
        onSignInClick={() => push('/signin')}
      />
    );
  }

  return (
    <ChangePasswordView
      formState={formState}
      onPasswordChange={handlePasswordChange}
      onPasswordConfirmationChange={handlePasswordConfirmationChange}
      onSubmit={handleSubmit}
    />
  );
};

// Выносим представления в отдельные компоненты
const InvalidTokenView = ({ onRecoveryClick, onSignInClick }) => (
  <div className="signin">
    <div className="signin__col signin__col_form">
      <LogoLink />
      <div className="signin__inner">
        <Space direction="vertical" size="middle">
          <div className="text-black-bold-f40-l48">Посилання більше неактивне</div>
          <Button
            text="Відновити пароль повторно"
            bg="pink"
            className="block"
            onClick={onRecoveryClick}
          />
          <Button text="Авторизуватись" bg="pink" className="block" onClick={onSignInClick} />
        </Space>
      </div>
    </div>
    <div className="signin__col signin__col_banner" />
  </div>
);

const ChangePasswordView = ({
  formState,
  onPasswordChange,
  onPasswordConfirmationChange,
  onSubmit,
}) => {
  const isSubmitDisabled =
    !validatePassword(formState.password) ||
    !validatePasswordMatch(formState.password, formState.passwordConfirmation);

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <LogoLink />
        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Пароль скинуто!</div>
          <div className="text-grey-dark-normal-f16-l28">
            Будь ласка, встановіть новий пароль для входу до кабінету орендаря.
          </div>
          <form onSubmit={onSubmit} className="form recovery-password-form">
            <InputText
              type="password"
              label="Новий пароль"
              id="password"
              value={formState.password}
              onChange={onPasswordChange}
              error={
                formState.showPasswordHint
                  ? `Пароль має містити не менше ${MINIMUM_PASSWORD_LENGTH} символів.`
                  : ''
              }
            />
            <InputText
              type="password"
              label="Повторіть пароль"
              id="passwordConfirmation"
              value={formState.passwordConfirmation}
              onChange={onPasswordConfirmationChange}
              error={formState.showMismatchError ? 'Паролі не співпадають.' : ''}
            />
            <Button
              type="submit"
              text="Встановити"
              bg="pink"
              className="block"
              disabled={isSubmitDisabled}
            />
            {formState.isSuccess && (
              <div className="text-green-normal-f16-l28">{formState.isSuccess}</div>
            )}
            {formState.errorsInPost && (
              <div className="text-red-normal-f16-l28">{formState.errorsInPost}</div>
            )}
          </form>
        </div>
      </div>
      <div className="signin__col signin__col_banner" />
    </div>
  );
};

const LogoLink = () => (
  <a href="/" className="signin__link">
    <img src="/images/logo.svg" alt="logo" />
  </a>
);

export default ChangePassword;
