import { Modal, Space, Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { apiInstance } from '../../utils/api';
import RequestItemAttachments from './RequestItemAttachments';
import RequestItemCommentList from './RequestItemCommentList';
import RequestItemLeaveComment from './RequestItemLeaveComment';

const adminMarketingCategory = '5369e6b8-4f16-45b3-8114-bdeeb2ca4969';
const adminMovingCategory = '5f72e68a-8bbb-4660-b442-e36df955db2e';
const adminTemporaryEntryCategory = '88a699c2-b2de-4bde-9ffd-c038a9a174f9';

const RequestItem = () => {
  const [request, setRequest] = useState({});
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false); // Добавляем состояние для модалки с ошибкой
  const [errorMessage, setErrorMessage] = useState(''); // Добавляем состояние для сообщения об ошибке
  const history = useHistory();

  const serviceCategoryRequest = request?.ServiceCategory?.value;

  const ROUTES = {
    REQUEST: new URL(
      `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=${id}&type=requestQuery`
    ),
    MESSAGES: new URL(`${process.env.REACT_APP_API_URL}/api/crm/get-data?id=${id}&type=message`),
    POST_MESSAGE: `${process.env.REACT_APP_API_URL}/api/post-data?id=${id}&type=message`,
    UPDATE_REQUEST: `${process.env.REACT_APP_API_URL}/api/post-data?type=update-request&id=${id}`,
  };

  const sendMessage = (message) => {
    return apiInstance(ROUTES.POST_MESSAGE, 'POST', { message })
      .then(() => {
        return apiInstance(ROUTES.MESSAGES, 'GET');
      })
      .then((messages) => {
        setMessages(messages);
        return messages;
      });
  };

  const handleCancelClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmCancel = () => {
    setShowConfirmModal(false);
    setLoadingCancel(true);

    const payload = {
      Status: '6e5f4218-f46b-1410-fe9a-0050ba5d6c38',
    };

    apiInstance(ROUTES.REQUEST, 'GET')
      .then((currentRequest) => {
        if (currentRequest.rows[0]?.Status?.value === 'ae5f2f10-f46b-1410-fd9a-0050ba5d6c38') {
          return apiInstance(ROUTES.UPDATE_REQUEST, 'POST', payload);
        } else {
          setRequest(currentRequest.rows[0]);
          throw new Error(
            'Вибачте, статус заявки було змінено, ви не можете скасувати заявку з цим статусом.'
          );
        }
      })
      .then(() => {
        return apiInstance(ROUTES.REQUEST, 'GET');
      })
      .then((updatedRequest) => {
        setRequest(updatedRequest.rows[0]);
        history.push('/requests');
      })
      .catch((error) => {
        console.error('Ошибка при отмене заявки:', error);
        setErrorMessage(error.message); // Устанавливаем сообщение об ошибке
        setShowErrorModal(true); // Показываем модалку с ошибкой
      })
      .finally(() => {
        setLoadingCancel(false);
      });
  };

  const handleCancelModal = () => {
    setShowConfirmModal(false);
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    setErrorMessage(''); // Очищаем сообщение об ошибке
  };

  useEffect(() => {
    apiInstance(ROUTES.REQUEST, 'GET').then((request) => {
      setRequest(request.rows[0]);
    });
    apiInstance(ROUTES.MESSAGES, 'GET').then((message) => {
      setMessages(message);
    });
  }, []);

  if (isEmpty(request))
    return (
      <Space className="loading" size="middle">
        <Spin size="large" />
      </Space>
    );

  const categoryMapSuWorkDesc = {
    [adminMovingCategory]: 'Кількість товару',
    [adminTemporaryEntryCategory]: 'Мета візиту',
  };
  const categoryMapSymptoms = {
    [adminTemporaryEntryCategory]: 'Доступ на вхід для',
  };

  return (
    <div className="request-page-item boxes">
      <div className="box request-item-header">
        <div className="text-black-medium-f22-l30">Сторінка запиту</div>
        <div className="request-item-header-btns">
          {request?.Status?.value === 'ae5f2f10-f46b-1410-fd9a-0050ba5d6c38' && (
            <>
              <Button
                text="Скасувати"
                bg="orange"
                onClick={handleCancelClick}
                loading={loadingCancel}
              />
              <Link to={`/edit-request/${id}`}>
                <Button text="Редагувати" bg="turquoise" />
              </Link>
            </>
          )}
          <Link to="/requests">
            <Button text="Назад" bg="turquoise" />
          </Link>
        </div>
      </div>
      {/* Модальное окно подтверждения */}
      <Modal
        className="success-modal"
        onCancel={handleCancelModal}
        open={showConfirmModal}
        footer={
          <div className="ant-btn-wrapper">
            <Button onClick={handleCancelModal} text="Ні" bg="orange" />
            <Button
              text="Так"
              bg="turquoise"
              onClick={handleConfirmCancel}
              loading={loadingCancel}
            />
          </div>
        }
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Скасування заявки</div>
          <div className="text-grey-dark-normal-f16-l28">
            Ви впевнені, що хочете скасувати заявку?
          </div>
        </div>
      </Modal>
      {/* Модальное окно ошибки */}
      <Modal
        className="success-modal"
        open={showErrorModal}
        footer={null}
        onCancel={handleErrorModalClose}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Помилка</div>
          <div className="text-grey-dark-normal-f16-l28">{errorMessage}</div>
        </div>
      </Modal>
      <div className="request-history-item-content">
        <div className="boxes__col request-info">
          <div className="box">
            <div className="request-info-header">
              <div className="text-black-medium-f22-l30">{request.Subject}</div>
              <div className="request-info-header-status">{request.Status?.displayValue}</div>
            </div>
            <div className="request-info-list">
              <div className="request-info-item">
                <div className="text-black-normal-f12-l17">ID звернення</div>
                <div className="text-black-normal-f16-l28">{request.Number}</div>
              </div>
              {request?.Category?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Тип звернення</div>
                  <div className="text-black-normal-f16-l28">{request.Category?.displayValue}</div>
                </div>
              )}
              {request?.SuContractorType?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Виконавець робіт</div>
                  <div className="text-black-normal-f16-l28">
                    {request.SuContractorType.displayValue}
                  </div>
                </div>
              )}

              {request?.SuStartWork && request?.SuEndWork && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Дата/період</div>
                  <div className="text-black-normal-f16-l28">
                    {moment(request.SuStartWork).format('DD.MM.YYYY HH:mm')} -{' '}
                    {moment(request.SuEndWork).format('DD.MM.YYYY HH:mm')}
                  </div>
                </div>
              )}
              {request?.ServiceCategory?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Категорія сервісу</div>
                  <div className="text-black-normal-f16-l28">
                    {request?.ServiceCategory?.displayValue}
                  </div>
                </div>
              )}
              {request?.SuServiceSubCategory?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Підкатегорія звернення</div>
                  <div className="text-black-normal-f16-l28">
                    {request.SuServiceSubCategory?.displayValue}
                  </div>
                </div>
              )}
              {request?.RespNightWork && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Виконавці робіт</div>
                  <div className="text-black-normal-f16-l28">{request.RespNightWork}</div>
                </div>
              )}
              {request?.SuClaimDepartment?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Департамент</div>
                  <div className="text-black-normal-f16-l28">
                    {request.SuClaimDepartment.displayValue}
                  </div>
                </div>
              )}
              {request?.SuTenantResonsible && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Відповідальна особа орендаря</div>
                  <div className="text-black-normal-f16-l28">{request.SuTenantResonsible}</div>
                </div>
              )}
              {request?.SuTelNumberTenant && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Телефон відповідальної особи</div>
                  <div className="text-black-normal-f16-l28">{request.SuTelNumberTenant}</div>
                </div>
              )}

              {request?.RespContractorTenantResonsible && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">
                    Відповідальна особа підрядної організації
                  </div>
                  <div className="text-black-normal-f16-l28">
                    {request.RespContractorTenantResonsible}
                  </div>
                </div>
              )}
              {request?.RespContractorTelNumberTenant && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">
                    Телефон відповідального підрядної організації
                  </div>
                  <div className="text-black-normal-f16-l28">
                    {request.RespContractorTelNumberTenant}
                  </div>
                </div>
              )}

              {request?.RespContractorName && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Назва підрядної організації</div>
                  <div className="text-black-normal-f16-l28">{request.RespContractorName}</div>
                </div>
              )}

              {request?.SuLoadingZone?.displayValue && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Зона завантаження</div>
                  <div className="text-black-normal-f16-l28">
                    {request.SuLoadingZone.displayValue}
                  </div>
                </div>
              )}
              {request?.SuCarNumber && (
                <div className="request-info-item">
                  <div className="text-black-normal-f12-l17">Номер авто</div>
                  <div className="text-black-normal-f16-l28">{request.SuCarNumber}</div>
                </div>
              )}
              {request?.Attachments[0]?.Id && <RequestItemAttachments request={request} />}
              {request?.Symptoms &&
                serviceCategoryRequest !== adminMovingCategory &&
                serviceCategoryRequest !== adminMarketingCategory && (
                  <div className="request-info-item">
                    <div className="text-black-normal-f12-l17">
                      {categoryMapSymptoms[serviceCategoryRequest] || 'Коментарі до запиту'}
                    </div>
                    <div className="text-black-normal-f16-l28">{request.Symptoms}</div>
                  </div>
                )}
              {request?.SuWorkDescription && (
                <div className="request-info-item">
                  {categoryMapSuWorkDesc[serviceCategoryRequest] || 'Опис робіт'}
                  <div className="text-black-normal-f16-l28">{request.SuWorkDescription}</div>
                </div>
              )}
            </div>
          </div>
          <RequestItemLeaveComment sendMessage={sendMessage} />
        </div>
        <div className="boxes__col request-history">
          <div className="box">
            <div className="text-black-medium-f22-l30">Історія повідомлень</div>
            <RequestItemCommentList messages={messages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
