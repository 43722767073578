import { Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { find } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoadingZone } from './LoadingZone';
import { useRequestContext } from './Request.Provider';

const exceptionCategory = [
  'aa5a0901-0ed4-4191-853e-16c33c7d3b57', // Ремонтні роботи
  'b204ab4c-6ca8-49e2-a1ef-68547c35818b', // ІТ роботи
  'd702ac89-b483-45d2-8d9f-91db02196f6f', // Регламентні роботи
  'ea5700f4-3499-4b19-b455-95210570fdb4', // Ремонт пожежної системи
  'c43f2d00-b024-441a-8a74-a6a0e299020f', // Технічні неполадки
];

const repairsCategory = 'aa5a0901-0ed4-4191-853e-16c33c7d3b57';
export const contractingOrganizations = '648b6226-ae39-4385-a20b-95ebe260b68d'; //Підрядні організації
export const internalResourceShoppingCenter = '4857f213-f264-4cc4-99e2-a234e3cb3efb'; //Внутрішній ресурс ТРЦ

export const RequestJob = ({
  requests,
  formContentItem,
  PrepareSelect,
  prepareSelectOptions,
  disabledForm,
  clickedSubmit,
}) => {
  const { availableRequestFields, renderAviableFieldsToEdit, form } = useRequestContext();

  const [selectedCategory, setSelectedCategory] = useState(availableRequestFields?.Category?.value);
  const [selectedContractor, setSelectedContractor] = useState(
    availableRequestFields?.SuContractorType?.value
  );
  const [filteredSubServiceCategory, setFiltredSubServiceCategory] = useState([]);

  const filterCategoriesJob = requests?.ServiceCategory?.filter(({ Id }) => {
    return exceptionCategory.includes(Id);
  });

  useEffect(() => {
    if (availableRequestFields?.Category) {
      setSelectedCategory(availableRequestFields?.Category?.value);
      setSelectedContractor(availableRequestFields?.SuContractorType?.value);
    }
  }, [availableRequestFields]);

  useEffect(() => {
    const categoryId =
      availableRequestFields?.Category?.value || form.getFieldValue('ServiceCategory');

    const subCategories = requests?.SuServiceSubCategory?.filter(
      (subCategory) => subCategory.categoryId === categoryId
    );
    setFiltredSubServiceCategory(subCategories);
  }, [
    form,
    requests,
    availableRequestFields?.Category?.value,
    form.getFieldValue('ServiceCategory'),
  ]);

  const exceptionWork = [
    'fe05744e-092b-41b7-a98c-bd2764355fa0', //Загальнобудівельні
    'be524ea5-f6d9-4cd5-b9a0-ba7756d2dc39', //Заміна лампочки
    '418a26b7-fec7-4e55-8966-9ba481b3e390', //Ремонт вентиляції
    '0184a995-ee00-4487-b4d6-4fc5848601c5', // Підключення до системи вентиляції
    'bd289bea-e884-499e-9b1a-057f4d26044f', //Підключення електрики
    '64370769-1c44-41fe-8577-5c4c8c8c137d', //Жироуловлювачі
    '244a265f-02fb-4f93-b96c-07500cce6b11', //Вода-Каналізація
  ];

  const SuServiceSubCategoryPermission = (required) => (
    <Form.Item
      name="SuServiceSubCategory"
      label="Підкатегорія сервісу"
      rules={[{ required: required, message: 'Вкажіть підкатегорію сервісу' }]}
    >
      <PrepareSelect
        defaultValue={availableRequestFields?.SuServiceSubCategory?.displayValue}
        options={prepareSelectOptions(filteredSubServiceCategory)}
        placeholder="Обрати"
        disabledForm={disabledForm}
      />
    </Form.Item>
  );

  const formPossibleFields = {
    RespNightWork: (
      <Form.Item
        name="RespNightWork"
        label="Виконавці робіт"
        rules={[{ required: true, message: 'Вкажіть виконавців робіт' }]}
      >
        <Input placeholder="Вкажіть виконавців робіт" disabled={disabledForm} />
      </Form.Item>
    ),
    RespContractorTenantResonsible: (
      <Form.Item
        name="RespContractorTenantResonsible"
        label="Відповідальна особа підрядної організації"
        rules={[{ required: true, message: 'Вкажіть відповідальну особу підрядної організації' }]}
      >
        <Input
          placeholder="Вкажіть відповідальну особу підрядної організації"
          disabled={disabledForm}
        />
      </Form.Item>
    ),

    RespContractorTelNumberTenant: (
      <Form.Item
        name="RespContractorTelNumberTenant"
        label="Телефон відповідального підрядної організації"
        rules={[
          { required: true, message: 'Вкажіть телефон відповідального підрядної організації' },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Поле пустое, пропускаем валидацию
              }

              // Проверяем, чтобы номер начинался с +38
              if (!value.startsWith('+38')) {
                return Promise.reject(new Error('Номер повинен починатися з +38'));
              }

              // Пропускаем проверки, если номер введен не полностью
              if (value.length < 6) {
                return Promise.resolve(); // Пропускаем валидацию
              }

              const operatorCode = value.substring(3, 6);

              // Проверяем справочник MobileCodes
              if (!requests || !requests.MobileCodes) {
                console.warn('Отсутствует справочник MobileCodes');
                return Promise.resolve(); // Пропускаем валидацию, если нет справочника
              }

              const isValidOperatorCode = find(requests.MobileCodes, { Code: operatorCode });
              if (!isValidOperatorCode) {
                return Promise.reject(new Error('Невірний код оператора'));
              }

              // Проверяем длину номера
              if (value.length !== 13) {
                return Promise.reject(new Error('Номер повинен містити 12 цифер'));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          placeholder="+380XXXXXXXX"
          disabled={disabledForm}
          maxLength={13}
          onKeyPress={(e) => {
            const allowedChars = /^[0-9+]$/; // Разрешенные символы
            if (!allowedChars.test(e.key)) {
              e.preventDefault(); // Запрещаем ввод, если символ не соответствует
            }
          }}
          onChange={(e) => {
            form.setFieldsValue({ RespContractorTelNumberTenant: e.target.value });
          }}
        />
      </Form.Item>
    ),

    RespContractorName: (
      <Form.Item
        name="RespContractorName"
        label="Назва підрядної організації"
        rules={[{ required: true, message: 'Вкажіть назву підрядної організації' }]}
      >
        <Input placeholder="Вкажіть назву підрядної організації" disabled={disabledForm} />
      </Form.Item>
    ),
    SuWorkDescription: (
      <Form.Item
        name="SuWorkDescription"
        label="Опис робіт"
        rules={[{ required: true, message: 'Вкажіть опис робіт' }]}
      >
        <Input placeholder="Якого характеру роботи" disabled={disabledForm} />
      </Form.Item>
    ),
    SuLoadingZone: (
      <LoadingZone
        defaultValue={availableRequestFields?.SuLoadingZone?.value}
        requests={requests}
        clickedSubmit={clickedSubmit}
        initialLoadingZone={form.getFieldValue('SuLoadingZone')}
      />
    ),
    SuContractorType: (
      <Form.Item
        name="SuContractorType"
        label="Виконавець робіт"
        rules={[{ required: true, message: 'Вкажіть виконавця робіт' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuContractorType?.displayValue}
          options={prepareSelectOptions(requests.SuContractorType)}
          placeholder="Обрати"
          onChange={(selectedContractor) => setSelectedContractor(selectedContractor)}
          disabledForm={disabledForm || availableRequestFields?.SuContractorType?.displayValue}
        />
      </Form.Item>
    ),
    Symptoms: (
      <Form.Item
        name="Symptoms"
        label="Коментарі до запиту"
        rules={[{ required: true, message: 'Вкажіть коментарі до запиту' }]}
      >
        <TextArea
          placeholder="Що плануєте завезти/вивезти (приблизну кількість і для чого)."
          disabled={disabledForm}
        />
      </Form.Item>
    ),
    SuServiceSubCategoryPermission: SuServiceSubCategoryPermission(true),
    SuServiceSubCategoryWithoutPermission: SuServiceSubCategoryPermission(false),
  };

  const isEditable = availableRequestFields?.Category?.value;

  return (
    <>
      {!isEditable && (
        <Form.Item
          name="ServiceCategory"
          label="Категорія сервісу"
          rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
        >
          <PrepareSelect
            options={prepareSelectOptions(filterCategoriesJob)}
            placeholder="Обрати"
            onChange={(selectedCategory) => {
              setSelectedCategory(selectedCategory);
            }}
            disabledForm={disabledForm}
          />
        </Form.Item>
      )}

      {selectedCategory === repairsCategory && (
        <>
          {formPossibleFields['SuContractorType']}
          {selectedContractor === contractingOrganizations && (
            <>
              {formContentItem.SuWork(moment(), moment().add(+1, 'hours'))}
              {!!filteredSubServiceCategory?.length &&
                formPossibleFields['SuServiceSubCategoryWithoutPermission']}
              {formContentItem.SuTenantResonsible}
              {formContentItem.SuTelNumberTenant}
              {formPossibleFields['RespContractorName']}
              {formPossibleFields['RespNightWork']}
              {formPossibleFields['RespContractorTenantResonsible']}
              {formPossibleFields['RespContractorTelNumberTenant']}
              {formPossibleFields['SuLoadingZone']}
              {formContentItem.SuCarNumber}
              {formContentItem.MarketingFile}
              {formPossibleFields['Symptoms']}
              {formPossibleFields['SuWorkDescription']}
            </>
          )}
          {selectedContractor === internalResourceShoppingCenter && (
            <>
              {formContentItem.SuWork(moment(), moment().add(+1, 'hours'))}
              {!!filteredSubServiceCategory?.length &&
                formPossibleFields['SuServiceSubCategoryPermission']}
              {formContentItem.SuTenantResonsible}
              {formContentItem.SuTelNumberTenant}
              {formContentItem.Symptoms}
              {formContentItem.SuWorkDescription}
            </>
          )}
        </>
      )}
      {selectedCategory && selectedCategory !== repairsCategory && (
        <>
          {selectedCategory === '5369e6b8-4f16-45b3-8114-bdeeb2e35763' &&
            formPossibleFields['SuServiceSubCategoryPermission']}
          {formContentItem.SuWork(moment(), moment().add(+1, 'hours'))}
          {formPossibleFields['SuWorkDescription']}
          {formContentItem.Symptoms}
        </>
      )}
    </>
  );
};

export default RequestJob;
