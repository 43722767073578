import { Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Svg from '../../components/SVG/SVG';
import { useContactStore } from '../../hooks/useContactStore';
import { apiInstance } from '../../utils/api';
import { downloadFile } from '../../utils/urlImgToBlob';

const ExpandableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`expandable-text f16-grey-dark ${
        isExpanded ? 'expandable-text--expanded' : 'expandable-text--collapsed'
      }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {text}
    </div>
  );
};

const columns = [
  {
    title: 'Дата рахунку',
    dataIndex: 'date',
    className: 'date center',
    width: 150,
  },
  {
    title: 'Дата платежу',
    dataIndex: 'RespDateOfHolding',
    className: 'date center',
    width: 150,
  },
  {
    title: 'Призначення платежу',
    dataIndex: 'purposeOfPayment',
    className: 'purpose-of-payment',
    width: 480,
  },
  {
    title: 'Номер рахунку',
    dataIndex: 'accountNumber',
    className: 'account-number center',
  },
  {
    title: 'Сума',
    dataIndex: 'amount',
    className: 'amount-sum center',
  },
  {
    title: 'Рахунок',
    dataIndex: 'score',
    className: 'score center',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    className: 'status center',
  },
];

const AccountTable = () => {
  const { currentContactStore } = useContactStore();
  const [counterparty, setCounterparty] = useState([]);

  const SuPurposeOfPayment = [
    'За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.',
    'За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.За послуги. Згідно рах. №9205 від 31.10.2022 Без ПДВ.',
  ];

  const ROUTES = {
    COUNTERPARTY: `${process.env.REACT_APP_API_URL}/api/crm/get-data?type=requestInvoice`,
  };

  useEffect(() => {
    apiInstance(ROUTES.COUNTERPARTY, 'GET')
      .then((res) => setCounterparty(res.rows))
      .catch((error) => console.log(error));
  }, [currentContactStore]);

  const download = useCallback((id) => {
    const ROUTES = {
      FILE: new URL(`${process.env.REACT_APP_API_URL}/api/files?fileId=${id}&type=receipt`),
    };
    return downloadFile(ROUTES.FILE);
  }, []);

  const prepareRequestData = useMemo(() => {
    return counterparty.map(
      ({
        StartDate,
        RespDateOfHolding,
        Amount,
        FileId,
        RespPurposeOfPayment,
        Number,
        PaymentStatus,
      }) => ({
        date: (
          <div className="f16-grey-dark">
            <div>{moment(StartDate).format('YYYY-MM-DD')}</div>
          </div>
        ),
        RespDateOfHolding: (
          <div className="f16-grey-dark">
            <div>
              {moment(RespDateOfHolding).isValid() && RespDateOfHolding
                ? moment(RespDateOfHolding).format('YYYY-MM-DD')
                : '-'}
            </div>
          </div>
        ),
        purposeOfPayment: (
          <ExpandableText text={RespPurposeOfPayment.length > 0 ? RespPurposeOfPayment : '-'} />
        ),
        accountNumber: <div className="f16-grey-dark">{Number}</div>,
        amount: <div className="f16-black">{Amount.toFixed(2)} грн</div>,
        score: FileId && (
          <div className="f16-black" onClick={() => download(FileId)}>
            Завантажити
            <Svg className="download" name="download" />
          </div>
        ),

        status: <div>{PaymentStatus.displayValue}</div>,
      })
    );
  }, [counterparty, download]);

  return (
    <Table
      className="default-table account-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
    />
  );
};

export default AccountTable;
