import cx from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../Guard/AuthContext';
import Svg from '../SVG/SVG';

const navList = [
  {
    link: '/',
    name: 'Головна',
    icon: 'main',
  },
  // {
  //   link: '/documents',
  //   name: 'Документи',
  //   icon: '',
  // },
  {
    link: '/accounts',
    name: 'Рахунки',
    icon: 'wallet',
  },
  // {
  //   link: '/marketing',
  //   name: 'Маркетинг-дані',
  //   icon: 'marketing',
  //   // disabled: true
  // },
  {
    link: '/requests',
    name: 'Звернення',
    icon: 'help',
  },
  {
    link: '/commodity-circulation',
    name: 'Товарообіг',
    icon: 'document',
  },
  // {
  //   link: '/stock',
  //   name: 'Акції',
  //   icon: 'others',
  // },
];

const Sidebar = () => {
  const [isVisibleSidebar, setVisibleSidebar] = useState(false);
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { user, currentContactStore } = useContext(AuthContext);

  // console.log(currentContactStore);

  const handleTab = (link, disabled) => {
    if (disabled) return;
    push(link);
  };

  const sidebarClassName = cx('sidebar', isVisibleSidebar && 'hidden');

  const sidebarToggleClassName = cx('sidebar__toggle', isVisibleSidebar && 'active');

  const sideBarList = useCallback(
    (list) => {
      return (
        <div className={sidebarClassName}>
          <div className="sidebar__wrapper">
            <a className="sidebar__link" href="/">
              <img className="sidebar__logo" src="/images/logo.svg" alt="logo" />
            </a>

            <button
              type="button"
              className={sidebarToggleClassName}
              onClick={() => setVisibleSidebar(!isVisibleSidebar)}
            >
              <Svg name="arrow-left" />
            </button>
          </div>

          <div className="sidebar-nav">
            {list.map(({ link, name, icon, disabled = false }) => {
              const exactLink = link;
              return (
                <button
                  className={cx('sidebar-nav__link', {
                    active:
                      pathname === exactLink ||
                      (pathname.startsWith(exactLink) && exactLink !== '/'),
                    disabled,
                  })}
                  key={link}
                  onClick={() => handleTab(link, disabled)}
                >
                  <Svg name={icon} />
                  {name}
                </button>
              );
            })}
          </div>
        </div>
      );
    },
    [pathname, isVisibleSidebar, currentContactStore]
  );

  // console.log(currentContactStore);

  const filteredNavList = useMemo(() => {
    return navList.filter((item) => {
      return !(
        (item.link === '/marketing' && currentContactStore?.marketing === false) ||
        (item.link === '/requests' && currentContactStore?.requests === false) ||
        (item.link === '/commodity-circulation' && currentContactStore?.commodity === false) ||
        (item.link === '/accounts' && currentContactStore?.accounts === false)
      );
    });
  }, [currentContactStore]);

  // if (!user) return sideBarList(navList);

  return sideBarList(filteredNavList);
};

export default Sidebar;
