import { Form } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { createContext, useContext, useEffect, useState } from 'react';
import AuthContext from '../../Guard/AuthContext';
import { convertBase64, session } from '../../helpers/helpers';

// ИСПОЛЬЗУЕМ apiInstance, как у вас в проекте
import { apiInstance } from '../../utils/api';

// Импорт компонентов, которые рендерятся в зависимости от типа заявки
import RequestAdministration from './RequestAdministration';
import RequestComplaint from './RequestComplaint';
import RequestFormContentItem, {
  PrepareSelect,
  prepareSelectOptions,
} from './RequestFormContentItem';
import RequestIncident from './RequestIncident';
import RequestJob from './RequestJob';
import RequestMarketing from './RequestMarketing';
import RequestOthers from './RequestOthers';
import RequestSKD from './RequestSKD';

// Устанавливаем таймзону по умолчанию
moment.tz.setDefault('Europe/Kiev');

// Создаём контекст, чтобы другие компоненты могли использовать состояние заявки
const RequestContext = createContext({});

export const useRequestContext = () => {
  return useContext(RequestContext);
};

// Список CRMId категорий, которые доступны
const aviableReferences = [
  'd7982047-eef2-435d-bf58-758c818433e4',
  'f8b0f38b-60fd-42d9-b87d-39e704ed6d27',
  '2a92dbea-2b8c-4a63-b3f6-ea4135f981d7',
];

// Основной провайдер, который оборачивает `RequestEditContent` и др.
const RequestProvider = ({ children, id }) => {
  // Достаём токен (при необходимости)
  const token = session.get('token');

  const currentContactStore = useContext(AuthContext);

  // Стейты для управления состоянием
  const [request, setRequest] = useState({});
  const [formUpdate, setFormUpdate] = useState(false);
  const [requests, setRequests] = useState([]);
  const [references, setReferences] = useState([]);
  const [referencesCategory, setReferencesCategory] = useState([]);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [errorsForm, setErrorsForm] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  // Инициализируем форму AntD
  const [form] = Form.useForm();
  const [formEdited, setFormEdited] = useState(false);
  const [sentValues, setSentValues] = useState(null);

  // Загружаем справочники (References) один раз
  useEffect(() => {
    if (!isEmpty(references?.length)) return;
    apiInstance(`${process.env.REACT_APP_API_URL}/api/requests/references`, 'GET').then((res) => {
      // Фильтруем категории только по aviableReferences
      const filtredReferences = res?.Category?.filter((item) =>
        aviableReferences.includes(item.crmId)
      );
      setReferences(res);
      console.log(res, 'References');
      setReferencesCategory(filtredReferences);
      console.log(referencesCategory, 'ReferencesCategory');
    });
  }, [references?.length]);

  // Загружаем общий список заявок (requests), если надо
  useEffect(() => {
    console.log('currentContactStore?.requests', currentContactStore?.requests);
    // Проверяем что requests пустой и currentContactStore?.requests существует
    if (!isEmpty(requests?.length) || currentContactStore?.requests) return;

    apiInstance(
      `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=sa3278462-sadkjhas-asd&type=requests`,
      'GET'
    ).then((res) => {
      console.log(res, 'res');
      setRequests(res);
    });
  }, [requests?.length, currentContactStore]);

  // Если передан id, загружаем конкретную заявку
  useEffect(() => {
    if (!id) return null;
    apiInstance(
      `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=${id}&type=requestQuery`,
      'GET'
    ).then((req) => {
      // console.log(req, 'req');
      // В req.rows может лежать массив. Берём первую строку
      setRequest(req.rows?.[0] || {});
    });
  }, [id, token]);

  // Формируем объект availableRequestFields - все поля, которые нужны для формы
  const availableRequestFields = {
    crmId: request?.Id,
    Id: request?.Number, // Номер заявки
    Type: request?.Category, // объект { value, name }?
    Category: request?.ServiceCategory, // объект { value, name }?
    SuContractorType: request?.SuContractorType,
    SuStartWork: request?.SuStartWork,
    SuEndWork: request?.SuEndWork,
    SuServiceSubCategory: request?.SuServiceSubCategory,
    RespNightWork: request?.RespNightWork,
    SuClaimDepartment: request?.SuClaimDepartment,
    SuTenantResonsible: request?.SuTenantResonsible,
    SuTelNumberTenant: request?.SuTelNumberTenant,
    RespContractorTenantResonsible: request?.RespContractorTenantResonsible,
    RespContractorTelNumberTenant: request?.RespContractorTelNumberTenant,
    RespContractorName: request?.RespContractorName,
    SuLoadingZone: request?.SuLoadingZone,
    SuCarNumber: request?.SuCarNumber,
    Symptoms: request?.Symptoms,
    SuWorkDescription: request?.SuWorkDescription,
    MarketingFile: request?.Attachments || null,
  };

  // Проверяем статус заявки. Если заявка не "Нове", показываем errorModal
  const checkRequestStatus = async () => {
    if (!availableRequestFields?.Id) return true; // Новая заявка, проверять нечего
    const requestFresh = await apiInstance(
      `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=${id}&type=requestQuery`,
      'GET'
    );
    // console.log(requestFresh, 'requestFresh');
    if (requestFresh?.rows?.[0]?.Status?.value !== 'ae5f2f10-f46b-1410-fd9a-0050ba5d6c38') {
      setErrorModal(true);
      return false;
    }
    return true;
  };

  // Синхронизируем значения формы при загрузке/обновлении заявки
  useEffect(() => {
    if (formEdited && sentValues) {
      // Если форму редактировали и есть уже "sentValues"
      form.setFieldsValue(sentValues);
    } else if (
      Object.values(availableRequestFields).every((value) => value !== undefined) &&
      !formEdited
    ) {
      // Если все поля определены и форму не редактировали вручную
      const fields = Object.entries(availableRequestFields).reduce((acc, [key, value]) => {
        // Проверяем, установлено ли уже значение этого поля в форме
        if (form.getFieldValue(key) === undefined) {
          if (value && typeof value === 'object' && 'value' in value) {
            // Если в поле лежит объект {value, name}, то в форму кладём .value
            acc[key] = value.value;
          } else {
            acc[key] = value;
          }
        }
        return acc;
      }, {});
      // Устанавливаем значения только для тех полей, которые еще не были установлены
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
      }
    }
  }, [availableRequestFields, form, formEdited, sentValues]);

  /**
   * Основная функция сабмита формы
   */
  const handleClickForm = async () => {
    // Если есть ошибки валидации — не сабмитим
    if (!isEmpty(errorsForm)) return null;
    setClickedSubmit(true);

    // Проверяем статус заявки (по желанию, если у вас есть такая логика)
    const isStatusValid = await checkRequestStatus();
    if (!isStatusValid) {
      setClickedSubmit(false);
      return null;
    }

    // Забираем все данные формы - ТОЛЬКО они
    const formData = form.getFieldsValue();
    // console.log('formData', formData);

    // Можно добавить requestType вручную, если нужно
    formData.requestType = 'case';

    // Разделяем поля, если у вас есть SuWork (диапазон дат) и attach_file_1 (файл)
    const { SuWork, attach_file_1, ...restFormData } = formData;

    // Обрабатываем диапазон дат (SuWork) — превращаем в SuStartWork и SuEndWork
    let suWorkStart = null;
    let suWorkEnd = null;
    if (SuWork && SuWork[0] && SuWork[1]) {
      suWorkStart = moment.tz(SuWork[0], 'Europe/Kiev').format('YYYY-MM-DDTHH:mm:ss.SSS');
      suWorkEnd = moment.tz(SuWork[1], 'Europe/Kiev').format('YYYY-MM-DDTHH:mm:ss.SSS');
    }

    // Подготавливаем "чистый" payload (ТОЛЬКО из формы)
    // При необходимости вручную добавляем suWorkStart/suWorkEnd
    let payload = {
      ...restFormData, // всё, что не SuWork / attach_file_1
      ...(suWorkStart ? { SuStartWork: suWorkStart } : {}),
      ...(suWorkEnd ? { SuEndWork: suWorkEnd } : {}),

      // requestType уже лежит в restFormData.requestType, либо можете подставить
      // requestType: restFormData.requestType,
    };

    // Если есть загруженный файл
    if (attach_file_1 && attach_file_1.file) {
      const base64file = await convertBase64(attach_file_1.file.originFileObj);
      payload.attach_file_1 = base64file;
      payload.attach_file_1_name = attach_file_1.file.name;
    }

    // console.log('final payload (only formData)', payload);

    // Отправляем либо на обновление, либо на создание
    let fetchRequest;
    if (availableRequestFields?.Id) {
      // update
      fetchRequest = apiInstance(
        `${process.env.REACT_APP_API_URL}/api/post-data?type=update-request&id=${id}`,
        'POST',
        payload
      );
    } else {
      // create
      fetchRequest = apiInstance(
        `${process.env.REACT_APP_API_URL}/api/post-data?type=create-request`,
        'POST',
        payload
      );
    }

    setSentValues(payload);

    fetchRequest
      .then(() => {
        form.resetFields();
        setSuccessModal(true);
        setClickedSubmit(false);
      })
      .catch((e) => {
        console.log('error', e);
        setClickedSubmit(false);
      });
  };

  /**
   * Вызывается при изменении значений формы
   */
  const onValuesFormChange = (changedValues) => {
    form.setFieldsValue(changedValues);
    setFormEdited(true);
    // Если заявка новая, проверяем валидацию на лету
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
      })
      .catch((error) => setErrorsForm(error.errorFields));
  };

  // Инициализируем RequestFormContentItem — компонент, который рендерит поля
  const formContentItem = RequestFormContentItem({
    form,
    availableRequestFields,
    references,
    clickedSubmit,
  });

  // Текущая сервисная категория
  const currentServiceCategory = availableRequestFields?.Category?.value;

  // Логика для "Запрос на обслуживание"
  const requestServiceList = [
    'SuWork',
    'ServiceCategory',
    'SuServiceSubCategory',
    // 'SuPremise',
    'Symptoms',
  ];

  // Если заявка не загружена, можно вернуть null или какой-то спиннер
  if (!request || !requests) return null;

  // В зависимости от CRMId, рендерим разные компоненты формы
  const formContent = {
    'd7982047-eef2-435d-bf58-758c818433e4': (
      <RequestAdministration
        form={form}
        requests={references}
        formUpdate={formUpdate}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
        currentServiceCategory={currentServiceCategory || form.getFieldValue('ServiceCategory')}
      />
    ),
    'f8b0f38b-60fd-42d9-b87d-39e704ed6d27': (
      <RequestJob
        form={form}
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        clickedSubmit={clickedSubmit}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
        currentServiceCategory={currentServiceCategory || form.getFieldValue('ServiceCategory')}
      />
    ),
    '1b0bc159-150a-e111-a31b-00155d04c01d': (
      <RequestIncident
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    'f03c5eed-1406-4f2e-a548-318eb70ab0a8': (
      <RequestComplaint
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    '2df70c10-3d3b-4936-bbb4-620747cd7da2': (
      <RequestOthers
        form={form}
        requests={references}
        formUpdate={formUpdate}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    '1c0bc159-150a-e111-a31b-00155d04c01d': (
      <>{requestServiceList.map((name) => formContentItem[name])}</>
    ),
    '2a92dbea-2b8c-4a63-b3f6-ea4135f981d7': <RequestSKD formContentItem={formContentItem} />,
    '0378d494-d47b-4f3a-9c9a-2c95658f6a90': <RequestMarketing formContentItem={formContentItem} />,
  };

  // Фильтруем поля, которые не пустые (если нужно рендерить только их)
  // Но при merge мы берём всё равно поля из availableRequestFields
  const nonEmptyFields = Object.entries(availableRequestFields).filter(
    ([, value]) => value !== null && value !== ''
  );

  const renderAviableFieldsToEdit = (formPossibleFields) => {
    return nonEmptyFields.map(([key]) => {
      switch (key) {
        case 'SuContractorType':
          return formPossibleFields['SuContractorType'];
        case 'SuStartWork' && 'SuEndWork':
          return formContentItem.SuWork(
            availableRequestFields['SuStartWork'],
            availableRequestFields['SuEndWork']
          );
        case 'SuServiceSubCategory':
          return formPossibleFields['SuServiceSubCategory'];
        case 'RespNightWork':
          return formPossibleFields['RespNightWork'];
        case 'SuClaimDepartment':
          return formContentItem.SuClaimDepartment;
        case 'SuTenantResonsible':
          return formContentItem.SuTenantResonsible;
        case 'SuTelNumberTenant':
          return formContentItem.SuTelNumberTenant;
        case 'RespContractorTenantResonsible':
          return formPossibleFields['RespContractorTenantResonsible'];
        case 'RespContractorTelNumberTenant':
          return formPossibleFields['RespContractorTelNumberTenant'];
        case 'RespContractorName':
          return formPossibleFields['RespContractorName'];
        case 'SuLoadingZone':
          return formContentItem.SuLoadingZone;
        case 'SuCarNumber':
          return formContentItem.SuCarNumber;
        case 'Symptoms':
          return formContentItem.Symptoms;
        case 'SuWorkDescription':
          return formContentItem.SuWorkDescription;
        case 'MarketingFile':
          return formContentItem.MarketingFile;
        default:
          return null;
      }
    });
  };

  // Собираем value-контекст, чтобы другие компоненты могли пользоваться
  const value = {
    availableRequestFields,
    request,
    requests,
    form,
    formUpdate,
    formContent,
    formContentItem,
    clickedSubmit,
    setClickedSubmit,
    handleClickForm,
    onValuesFormChange,
    successModal,
    setSuccessModal,
    errorModal,
    setErrorModal,
    references,
    referencesCategory,
    renderAviableFieldsToEdit,
  };

  // Возвращаем провайдер
  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};

export default RequestProvider;
