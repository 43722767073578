import { Form, Input, Modal } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import { apiInstance } from '../../utils/api';
import { currentDate, ROUTES } from './CommodityHelpers';

const CommodityDaily = ({ disabled, activeKey }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const [form] = Form.useForm();

  const handleNumberInput = (e, fieldName) => {
    const input = e.target;
    const { value, selectionStart } = input;

    form.setFieldsValue({ [fieldName]: value });

    // Restore cursor position after React rerender
    setTimeout(() => {
      input.setSelectionRange(selectionStart, selectionStart);
    }, 0);
  };

  const validateNumberInput = (event) => {
    const { value, selectionStart } = event.target;
    const key = event.key;

    // Разрешаем только цифры и точку
    if (!/[0-9.]/.test(key)) {
      event.preventDefault();
      return;
    }

    // Разрешаем только одну точку
    if (key === '.' && value.includes('.')) {
      event.preventDefault();
      return;
    }

    // Разрешаем не более двух цифр после точки
    if (value.includes('.')) {
      const [, decimal] = value.split('.');
      const cursorAfterDot = selectionStart > value.indexOf('.');

      if (decimal && decimal.length >= 2 && cursorAfterDot && key !== '.') {
        event.preventDefault();
        return;
      }
    }

    // Не позволяем начинать с нуля, если это не десятичная часть
    if (value === '' && key === '0') {
      event.preventDefault();
      return;
    }
  };

  const onFinish = (values) => {
    setClickedSubmit(true);
    apiInstance(ROUTES.POST_REQUESTS, 'POST', {
      requestType: activeKey,
      day: currentDate,
      tradeWithVat: values.tradeWithVat,
      tradeWithoutVat: values.tradeWithoutVat,
    });
    setSuccessModal(true);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    setError(true);
    setSuccess(false);
  };

  const renderCommodityFooter = useMemo(() => {
    return (
      <div
        className={cx(
          'commodity-form__footer-title',
          { success: success && !error },
          { error: error && !success }
        )}
      >
        {success && !error && 'Дані було успішно відправлено'}
        {error && !success && 'Заповніть усі поля'}
      </div>
    );
  }, [success, error]);

  return (
    <>
      <div className="commodity__title text-black-bold-f19-l33">
        Звіт за {moment().subtract(1, 'day').format('DD.MM.YYYY')}
      </div>
      <div className="commodity__text text-grey-normal-f16-l28">
        {disabled
          ? 'Звіт буде доступний 04.12.22'
          : 'Будь ласка, заповніть усі необхідні дані для створення заявки.'}
      </div>

      <Form
        className={cx('commodity-form', { disabled: disabled })}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={false}
        form={form}
      >
        <div className="commodity-form__row">
          <div className="commodity-form__col">
            <Form.Item
              name="tradeWithVat"
              label="Сумарний товарообіг за добу (з ПДВ):"
              rules={[
                { required: true, message: 'Введіть сумарний товарообіг за добу (з ПДВ)' },
                {
                  validator: (_, value) => {
                    if (value === undefined || value === null || value === '') {
                      return Promise.resolve();
                    }
                    if (!/^[1-9]\d*(\.\d{0,2})?$/.test(value)) {
                      return Promise.reject(
                        new Error('Введіть число більше 0, до 2 знаків після крапки')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Введіть суму"
                suffix={<span className="text-grey-normal-f16-l28">грн</span>}
                onChange={(e) => handleNumberInput(e, 'tradeWithVat')}
                onKeyPress={validateNumberInput}
              />
            </Form.Item>

            <Form.Item
              name="tradeWithoutVat"
              label="Сумарний товарообіг за добу (без ПДВ):"
              rules={[
                { required: true, message: 'Введіть сумарний товарообіг за добу (без ПДВ)' },
                {
                  validator: (_, value) => {
                    if (value === undefined || value === null || value === '') {
                      return Promise.resolve();
                    }
                    if (!/^[1-9]\d*(\.\d{0,2})?$/.test(value)) {
                      return Promise.reject(
                        new Error('Введіть число більше 0, до 2 знаків після крапки')
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Введіть суму"
                suffix={<span className="text-grey-normal-f16-l28">грн</span>}
                onChange={(e) => handleNumberInput(e, 'tradeWithoutVat')}
                onKeyPress={validateNumberInput}
              />
            </Form.Item>

            <Form.Item>
              <Button type="submit" text="Надіслати" bg="pink" disabled={clickedSubmit}>
                Надіслати
              </Button>
            </Form.Item>

            <div className="commodity-form__footer">
              {(error || success) && renderCommodityFooter}
            </div>
          </div>
        </div>
      </Form>

      <Modal
        className="success-modal"
        open={successModal}
        footer={null}
        onCancel={() => {
          setSuccessModal(false);
          setClickedSubmit(false);
        }}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Дякуємо</div>
          <div className="text-grey-dark-normal-f16-l28">Добовий товарообіг успішно надіслано</div>
        </div>
      </Modal>
    </>
  );
};

export default CommodityDaily;
